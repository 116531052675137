<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <b-card-group columns :style="cardGroupStyle">
            <cms-block-selector v-for="child in block.children" :key="child.id" block-component="b-card" no-body
                                 @block-selected="selectBlock" :block="child" :block-selection="blockSelection" is-child :editable="editable"/>
        </b-card-group>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";

// cms-block-gallery #hot-reload-debug
export default {
    name: `cms-block-gallery`,
    components: {
        CmsBlockSelector: () => import(`@/components/editor/rendering/blocks/cms-block-selector.vue`),
        CmsBlock
    },
    extends: CmsBlock,
    computed: {
        cardGroupStyle() {
            let style = {
                columnGap: this.block.columnGap
            }
            return style;
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    ::v-deep .cms-component {
        margin: 0;
    }
}
</style>
